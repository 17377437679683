import React from 'react';

const LookingForJob = () => {
  const redirectUrl = 'https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96';

  return (
    <section className="max-w-5xl mx-auto my-12 p-8 bg-white">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">
        <a href={redirectUrl} className="text-blue-500 hover:underline">
          Job Application | Placement Consultants
        </a>
      </h1>
      <p className="text-gray-700 mb-4">
        Study Solutions is the best <a href={redirectUrl} className="text-blue-500 hover:underline">placement
             consultants in Delhi</a> armed with more than 12 years of HR domain experienced professionals
              working with us. Study Solutions provides jobs in India as well as Overseas. Study Solutions provides jobs in Manufacturing Company, Jobs in IT Company, Jobs in
               Pharmaceutical Company, Jobs in Banking & Finance, Jobs in Automobile Company, Jobs in Engineering
                Company and many more;
      </p>
      <p className="text-gray-700 mb-4">
      Study Solutions, also known as <a href={redirectUrl} className="text-blue-500 hover:underline">top recruitment consultancy
         in Delhi</a>. Accord Consultants provides wide range of HR services as,
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-6">
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Placement Consultancy</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Competency Mapping Services</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">IT Recruitment Agency Services</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Staffing Solutions</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Head Hunting</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Career Consultants</a></li>
        <li><a href={redirectUrl} className="text-blue-500 hover:underline">Top Management CEO, CFO, COO recruitment</a></li>
      </ul>
      <p className="font-semibold italic text-gray-700 mb-4">Disclaimer: Please read this carefully</p>
      <p className="text-gray-700 mb-6">
        This is to all our stakeholder. We do not offer any foreign education to any
        candidate or person(s). We do offer job to freshers as well as professional but
        we do not ask of any money and we do not charge for offering a job in India as
        well as Overseas. We do not ask for any money or kind of money for Overseas jobs.
         We do offer jobs but we do not guarantee for job placement. Study Solutions is Delhi
         based placement consultancy working of PAN India basis and our official website is
         only www.studysolutions.xyz
      </p>
      <p className="text-gray-700 mb-6">
        By clicking on the button below, “Upload Resume”, you hereby consent to our
         disclaimer and agree to its terms.
      </p>
      <a href={redirectUrl}>
        <button className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600">
          Upload Resume
        </button>
      </a>
    </section>
  );
};

export default LookingForJob;
