import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section className="max-w-6xl mx-auto my-12 p-8 bg-white text-gray-800">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">Effective Date: 01st May 2024</p>
      
      <p className="mb-4">
      Study Solutions (“we”, “us”, “our”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal information when you visit our website <a href="https://studysolutions.xyz" className="text-blue-600 hover:underline">https://studysolutions.xyz</a> (the “Site”) and use our services. By accessing or using our Site, you agree to the terms of this Privacy Policy.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
      
      <h3 className="text-xl font-semibold mb-2">1.1 Personal Information</h3>
      <p className="mb-4">
        When you apply for a job or submit information through our Site, we may collect personal information that includes but is not limited to:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Name</li>
        <li>Contact information (e.g., email address, phone number)</li>
        <li>Resume and cover letter</li>
        <li>Job application details</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">1.2 Usage Data</h3>
      <p className="mb-4">
        We may also collect information about how you use our Site, including:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>IP address</li>
        <li>Browser type</li>
        <li>Device information</li>
        <li>Pages visited and time spent on the Site</li>
        <li>Referring website</li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">1.3 Cookies and Tracking Technologies</h3>
      <p className="mb-4">
        Our Site uses cookies and similar tracking technologies to enhance user experience, analyze Site usage, and deliver personalized content. You can control cookies through your browser settings.
      </p>

      <h2 className="text-2xl font-semibold mb-2">How We Use Your Information</h2>
      <p className="mb-4">We use the information we collect for the following purposes:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>Job Applications: To process and manage your job applications.</li>
        <li>Site Improvement: To analyze usage and improve our Site’s functionality and user experience.</li>
        <li>Communication: To respond to inquiries, provide support, and send notifications related to job applications and updates.</li>
        <li>Marketing: To send you promotional materials, if you have opted-in to receive them.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">How We Share Your Information</h2>
      <p className="mb-4">
        We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li>Service Providers: With third-party service providers who assist us in operating our Site and providing our services (e.g., hosting providers).</li>
        <li>Legal Requirements: If required by law or in response to valid requests by public authorities.</li>
        <li>Business Transfers: In connection with a merger, acquisition, or sale of assets.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Data Security</h2>
      <p className="mb-4">
        We implement reasonable technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Your Choices</h2>
      <p className="mb-4">You have the following rights regarding your personal information:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>Access and Correction: You can request access to or correction of your personal information.</li>
        <li>Opt-Out: You can opt-out of receiving promotional communications from us.</li>
        <li>Deletion: You can request the deletion of your personal information, subject to legal and contractual obligations.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">Third-Party Links</h2>
      <p className="mb-4">
        Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review the privacy policies of any third-party sites you visit.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Children’s Privacy</h2>
      <p className="mb-4">
        Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware of any information collected from a child under 13, we will take steps to delete it.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Changes to This Privacy Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically.
      </p>

      <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </p>
      <address className="not-italic">
      Study Solutions<br />
        Email: <a href="mailto:support@studysolutions.xyz" className="text-blue-600 hover:underline">support@studysolutions.xyz</a>
      </address>
    </section>
  );
};

export default PrivacyPolicy;
