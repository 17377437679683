import React from 'react';

const Footer = () => {
  const externalLink = "https://www.highratecpm.com/yh8hym39n?key=e3824d37603ed34a877bd99e95254c96";

  return (
    <footer className="bg-black text-white py-12">
      <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
        {/* Column 1 */}
        <div>
          <ul>
            <li className="mb-2">
              <a href="/free-career-tips" className="hover:underline">
                Free Career Tips
              </a>
            </li>
            <li className="mb-2">
              <a href="/current-openings" className="hover:underline">
                Current Openings
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Overseas Placement Services
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Competency Mapping Services
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Manpower Placement Consultancy
              </a>
            </li>
          </ul>
        </div>

        {/* Column 2 */}
        <div>
          <ul>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Jobs in FMCG
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Jobs in Finance
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Jobs in Real Estate
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Jobs in Automobile
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Jobs in Manufacturing
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Jobs in Pharmaceutical
              </a>
            </li>
            <li className="mb-2">
              <a href={externalLink} className="hover:underline" target="_blank" rel="noopener noreferrer">
                Jobs in Chemical Company
              </a>
            </li>
          </ul>
        </div>

        {/* Column 3 */}
        <div>
          <ul>
            <li className="mb-2">
              <a href="/what-we-do" className="hover:underline">
                About Us
              </a>
            </li>
            <li className="mb-2">
              <a href="/contact-us" className="hover:underline">
                Contact Us
              </a>
            </li>
            <li className="mb-2">
              <a href="/privacy-policy" className="hover:underline">
                Privacy Policy
              </a>
            </li>
            <li className="mb-2">
              <a href="/Disclaimer" className="hover:underline">
                Disclaimer
              </a>
            </li>
            <li className="mb-2">
              <a href="/TandC" className="hover:underline">
                Terms & Conditions
              </a>
            </li>
          </ul>
        </div>

        {/* Column 4 */}
        <div className="text-center md:text-right">
          <a href='/'>
            <h3 className="text-2xl font-bold mb-4">Study Solutions</h3>
          </a>
          <p className="text-gray-500">© 2024 Study Solutions.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
